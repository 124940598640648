import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../layouts"
import Seo from "../components/seo"
import { Link } from "gatsby"
import {
  Section,
  Container,
  BreadCrumb,
  SectionTitle,
  SectionPageTitle,
  SectionDescription,
  SectionInnerWhite,
} from "../components/Section"
import Badge from "../components/Badge"
import FaqForm from "../components/FaqForm"
import {
  Accordions,
  Accordionsitems,
  AccordionsContent,
  AccordionsContentinner,
  AccordionsTitle,
} from "../components/Accordion"
import BreakpointUp from "../components/Media/BreakpointUp"

const BadgeContainter = styled.ul`
  max-width: 950px;
  width: 100%;
  margin: 0 auto 30px;
  ${BreakpointUp.xxl`
    margin:0 auto 60px;
  `}
`
const BadgeHorizontal = styled.ul`
  list-style: none;
  margin: -5px;
  padding: 0;
  text-align: center;
`
const BadgeHorizontalItem = styled.li`
  margin: 0;
  padding: 5px;
  display: inline-block;
  .badge {
    background: ${props => (props.active ? "#EBA300" : "#fff")};
    border-color: ${props => (props.active ? "#EBA300" : "#CCD6E0")};
    color: ${props => (props.active ? "#fff" : "#033366")};
    padding: 10px 25px;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    .badge {
      color: #fff;
      background-color: #eba300;
      border-color: #eba300;
    }
  }
`

const FormContainer = styled.div`
  .form-action {
    text-align: center;
    margin: 30px 0 0;
    ${BreakpointUp.md`        
        position: absolute;
        left: 0;
        right: 0;            
        top: calc(100% - 22px);        
        margin: 0;
    `}
    ${BreakpointUp.lg`             
        top: calc(100% - 26px);
    `}
  }
  textarea.form-control {
    height: 82px;
    ${BreakpointUp.lg`
      height: 150px;
    `}
    ${BreakpointUp.xl`
      height: 200px;
    `}
    ${BreakpointUp.xxl`
      height: 300px;
    `}
  }
`

const FaqPage = ({ location, data }) => {
  const [currentCategory, setCurrentCategory] = useState("All")
  let currentFaq = []
  data.allContentfulMainCategoryLanding.edges.map(item => {
    return item.node.faqs.map(faq => currentFaq.push(faq))
  })

  const [faqs, setFaqs] = useState(currentFaq)

  const [activeFaq, setActiveFaq] = useState(null)
  function handleAccodion(key) {
    if (activeFaq === key) {
      return setActiveFaq(null)
    }
    setActiveFaq(key)
  }
  useEffect(() => {
    if (currentCategory === "All") {
      let tempFaqs = []
      data.allContentfulMainCategoryLanding.edges.map(item => {
        return item.node.faqs.map(faq => tempFaqs.push(faq))
      })
      setFaqs(tempFaqs)
    } else {
      let tempFaqs = []
      data.allContentfulMainCategoryLanding.edges.map(item => {
        if (item.node.category.name === currentCategory) {
          return item.node.faqs.map(faq => tempFaqs.push(faq))
        }
        return null
      })
      setFaqs(tempFaqs)
    }
  }, [currentCategory, setCurrentCategory])
  return (
    <Layout isHeader={true} location={location}>
      <Seo
        title="Frequently asked Questions about Metal Building Installation"
        description="Here is a list of the most frequently asked questions on metal building pricing, ordering, site preparation, delivery, and installation."
      />
      <BreadCrumb>
        <Container maxWidth="100%" pl="3%" pr="3%">
          <Link to="/">Home</Link>
          <span>FAQ</span>
        </Container>
      </BreadCrumb>
      <Section
        xpt="170px"
        mpt="150px"
        pt="120px"
        xpb="30px"
        mpb="20px"
        pb="15px"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionPageTitle>How Can We Help?</SectionPageTitle>
          <SectionDescription maxWidth="760px">
            <p>
              Have some questions about your dream metal building? Not a
              problem, we’re here to help!
            </p>
          </SectionDescription>
          <BadgeContainter>
            <BadgeHorizontal>
              <BadgeHorizontalItem
                key="All"
                active={currentCategory === "All" ? true : false}
                onClick={() => setCurrentCategory("All")}
              >
                <Badge text="All" />
              </BadgeHorizontalItem>
              {data.allContentfulMainCategoryLanding.edges.map((item, i) => (
                <BadgeHorizontalItem
                  onClick={() => setCurrentCategory(item.node.category.name)}
                  key={item.node.category.name}
                  active={
                    currentCategory === item.node.category.name ? true : false
                  }
                >
                  <Badge text={item.node.category.name} />
                </BadgeHorizontalItem>
              ))}
            </BadgeHorizontal>
          </BadgeContainter>
          <SectionInnerWhite xpl="0" xpr="0" mpl="0" mpr="0" pl="0" pr="0">
            <Accordions>
              {faqs.map((item, index) => (
                <Accordionsitems
                  className={activeFaq === index ? "active" : ""}
                  key={index}
                >
                  <AccordionsTitle onClick={() => handleAccodion(index)}>
                    {item.question}
                    <span></span>
                  </AccordionsTitle>
                  <AccordionsContent className="accordions-content">
                    <AccordionsContentinner>
                      {item.answer}
                    </AccordionsContentinner>
                  </AccordionsContent>
                </Accordionsitems>
              ))}
            </Accordions>
          </SectionInnerWhite>
        </Container>
      </Section>
      <Section
        xpt="30px"
        mpt="20px"
        pt="15px"
        xpb="90px"
        mpb="70px"
        pb="50px"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionInnerWhite>
            <SectionTitle>You still have a question?</SectionTitle>
            <SectionDescription maxWidth="1140px">
              <p>
                If you cannot find answer to your question in our FAQ, you can
                always contact. We will answer to you shortly!
              </p>
            </SectionDescription>
            <FormContainer>
              <FaqForm location={location} />
            </FormContainer>
          </SectionInnerWhite>
        </Container>
      </Section>
    </Layout>
  )
}

export default FaqPage

export const query = graphql`
  query FaqPageQuery {
    allContentfulMainCategoryLanding {
      edges {
        node {
          category {
            name
          }
          faqs {
            question
            answer
          }
        }
      }
    }
  }
`
