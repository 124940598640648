import styled from 'styled-components'
import BreakpointUp from '../Media/BreakpointUp'
import BreakpointDown from '../Media/BreakpointDown'

export const Form = styled.div`
    max-width:888px;
    width:100%;
    margin:0 auto;
    .form-group{
        .form-control{
            background-color:transparent;
            color: #000;
            font-weight:400;
            &::placeholder {
                color: #000;
            }
            &:focus{
              border-color:#000;
            }
        }
        textarea{
            max-height:300px;
        }
    }
`
export const FormRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin:0 -15px;
    ${BreakpointDown.md`
     margin:0 -10px;
    `}
`
export const FormTwoCol = styled.div`
    position: relative;
    width:100%;
    padding:0 15px;
    ${BreakpointUp.sm` 
        flex: 0 0 50%;
        max-width: 50%;
    `}
     ${BreakpointDown.md`
     padding:0 10px;
    `}
`
export const FormCol = styled.div`
    position: relative;
    width:100%;
    padding:0 15px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    ${BreakpointDown.md`
     padding:0 10px;
    `}
`
export const FormAction = styled.div`
    display:flex;
    flex-wrap:wrap;
    justify-content:${props=>props.justifyContent};
`