import React from "react"
import axios from "axios"
import { navigate } from "gatsby"
import PrimaryButton from "../Button/PrimaryButton"
import { Form, FormRow, FormTwoCol, FormCol, FormAction } from "../Form"
import StateSelect from "../StateSelect"

class FaqForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      source: "",
      page: "",
      form: "",
      name: "",
      email: "",
      phone_no: "",
      state: null,
      zip_code: "",
      message: "",
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    const { id, value } = event.target
    this.setState({
      [id]: value,
    })
  }
  handleChangeSelect(state) {
    this.setState({ state })
  }
  handleSubmit(event) {
    event.preventDefault()
    const { location } = this.props
    const data = {
      source: location.origin,
      page: location.href,
      form: event.target.id,
      full_name: this.state.name,
      email: this.state.email,
      mobile_no: this.state.phone_no,
      state: this.state.state.value,
      zip_code: this.state.zip_code,
      commnet: "Message: " + this.state.message,
      api_token: process.env.GATSBY_FORM_CRM_TOKEN,
    }

    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN },
    })
      .then(res => {
        this.setState({
          source: "",
          page: "",
          form: "",
          name: "",
          email: "",
          phone_no: "",
          state: "",
          zip_code: "",
          message: "",
        })
        navigate("/thank-you-for-your-question/")
      })
      .catch(error => {
        alert(error)
      })
  }
  render() {
    return (
      <form
        id="requestmetalbuilding"
        onSubmit={this.handleSubmit}
        className="requestmetalbuilding"
      >
        <Form className="form">
          <FormRow>
            <FormTwoCol>
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  placeholder="Name"
                  onChange={this.handleChange}
                  required
                />
              </div>
            </FormTwoCol>

            <FormTwoCol>
              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="Email Address"
                  onChange={this.handleChange}
                  required
                />
              </div>
            </FormTwoCol>
          </FormRow>

          <FormRow>
            <FormCol>
              <div className="form-group">
                <input
                  type="tel"
                  id="phone_no"
                  className="form-control"
                  placeholder="Phone Number"
                  onChange={this.handleChange}
                  pattern="[0-9]{10}"
                  required
                />
              </div>
            </FormCol>
          </FormRow>

          <FormRow>
            <FormTwoCol>
              <div className="form-group form-group-select">
                <StateSelect
                  id="state"
                  value={this.state.state}
                  onChange={this.handleChangeSelect}
                />
              </div>
            </FormTwoCol>
            <FormTwoCol>
              <div className="form-group">
                <input
                  type="number"
                  id="zip_code"
                  className="form-control"
                  placeholder="Zip Code"
                  onChange={this.handleChange}
                  required
                />
              </div>
            </FormTwoCol>
          </FormRow>

          <FormRow>
            <FormCol>
              <div className="form-group form-group-textarea">
                <textarea
                  type="text"
                  id="message"
                  className="form-control"
                  placeholder="Type your message here…"
                  onChange={this.handleChange}
                  required
                  title=" "
                />
              </div>
            </FormCol>
          </FormRow>
          <FormAction className="form-action" justifyContent="center">
            <button type="submit" aria-label="button" className="btn-block-lg">
              <PrimaryButton text="Send Message" size="lg" />
            </button>
          </FormAction>
        </Form>
      </form>
    )
  }
}

export default FaqForm
