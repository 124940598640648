import React from 'react'
import styled from "styled-components"
const Badge = styled.span`    
    border: 1px solid #EBA300;
    background-color: transparent;   
    font-weight: 500;
    color:#EBA300;     
    padding: 6px 20px;
    display:inline-flex;
    align-items:center;
    border-radius:100px;
    transition: color .5s ease-in-out, background-color .5s ease-in-out, border-color .5s ease-in-out;    
    > .text{
        line-height:20px;       
        + .icon{
            margin-left:10px;
        } 
    }
    > .icon{
        height:20px;
        display: flex;
        align-items: center;
        svg{
            fill:#fff;
        }
        &:empty {
			display: none;
		}
    }
    /* &:hover, &:focus{
        color:#fff;
        background-color: #EBA300;
        border-color:#EBA300;
    } */
`

const BadgeList = (props) => {
    const { text, icon } = props;    
    return (
        <Badge className="badge">
            <>
                { text &&
                    <span className='text'>{text}</span>
                }
                { icon &&
                    <span className='icon'>{icon}</span>
                }
            </>
        </Badge>    
    )
}
export default BadgeList
